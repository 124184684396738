.answers{
  min-width: 500px;
  margin-bottom: 1rem;
}

.form-label {
  font-style: italic;
}

.answers .form-group {
  margin-bottom: 1rem;
}
.connections .form-group {
  margin-bottom: 0.5rem;
}

/* Interaction.css */
.input-group-flex {
  display: flex;
  align-items: center;
}

.input-group-flex .form-range {
  flex-grow: 1;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.input-group-flex .input-group-text {
  flex-shrink: 0;
  flex-basis: auto;
}
